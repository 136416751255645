import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="App-name">
         Agata Jones
        </p>
          <p>
              Frontend Developer & JavaScript Tamer
          </p>
          <p>
              Content on the way!
          </p>
      </header>
    </div>
  );
}

export default App;
